<template>
  <div class="regional">
    <div class="bg-only"></div>
    <section class="hero">
      <div class="goback" @click="goback">
          <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" fill-rule="nonzero"/></svg>
        <p>back to Regional Rumble</p>
      </div>
      <img alt="Regional Rumble MENA Logo" src="https://cms.aplesports.com/storage/uploads/2023/09/18/6508629a9ba86rr23-brand-logo-mena-white.png" />
    </section>
    <section class="info">
      <h1>MENA Rumble</h1>
      <div class="infomercial">
        <div>
          <h2>Biweekly Rumble - <br />$2,500 each for ME / $1,000 each for NAF</h2>
          <ul>
            <li><b>ME:</b> 5th October / 19th October / 2nd November 2023</li>
            <li><b>NAF:</b> 6th October / 20th October / 3rd November 2023</li>
          </ul>
        </div>
        <div>
          <h2>Regional Rumble - $20,000</h2>
          <ul>
            <li><b>Day 1:</b> 17th November 2023</li>
            <li><b>Day 2:</b> 24th November 2023</li>
          </ul>
        </div>
      </div>
      <div class="register">
        <a class="btn" href="https://www.start.gg/tournament/mena-regional-rumble">Sign Up</a>
      </div>
    </section>
    <section class="trailer">
      <iframe src="https://www.youtube.com/embed/b53E8v2tFYw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </section>
    <footer>
      Rocket League, Psyonix, and all related marks and logos are trademarks or registered trademarks of Psyonix LLC and used under license.  &copy; 2015-2023 Licensed by Psyonix LLC, All rights reserved. All other trademarks are property of their respective owners.
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MERumble',
  methods: {
    goback() {
      this.$router.push('/regionalrumble');
    },
    signup() {
      alert('Signups open soon!');
    }
  },
}
</script>
